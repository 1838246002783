import React, { useState } from "react";
import { List } from "@material-ui/core";
import {
  AllTimeAccordion,
  ThisWeekAccordion,
  PaymentDueAccordion,
} from "./components";
import SimplePage from "../SimplePage";

const InvoicePage = () => {
  const [refetch, setRefetch] = useState(true);

  return (
    <SimplePage className="InvoicePage" pageTitle="Invoices">
      <List>
        <AllTimeAccordion setRefetch={setRefetch} refetch={refetch} />
        <PaymentDueAccordion setRefetch={setRefetch} refetch={refetch} />
        <ThisWeekAccordion setRefetch={setRefetch} refetch={refetch} />
      </List>
    </SimplePage>
  );
};

export default InvoicePage;
