import { Grid } from "@material-ui/core";
import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import CommonAccordion from "../../../components/CommonAccordion";
import CommonSelect from "../../../components/CommonSelect";
import CustomTablePagination from "../../../components/CustomTablePagination";
import { DataContext, SettingContext } from "../../../DataContext";
import { InvoiceContent } from "./InvoiceContent";
import { InvoiceSummary } from "./InvoiceSummary";

export const PaymentDueAccordion = ({ setRefetch, refetch }) => {
  const { alertError } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(true);
  const [pageData, setPageData] = useState([]);
  const [paymentDue, setPaymentDue] = useState("over-due");
  const { invoiceSetting, setInvoiceSetting } = useContext(SettingContext);

  const fetchData = () => {
    setIsLoading(true);
    const link = `/view-invoices/payment-due?page=${invoiceSetting.currentPage}&size=${invoiceSetting.pageSize}&showArchivedData=${invoiceSetting.showArchivedData}&dueType=${paymentDue}`;
    Axios.get(link)
      .then((res) => {
        setPageData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setPageData(null);
        setIsLoading(false);
        alertError("Failed to load Payment Due invoices");
      });
  };

  const handleChangePage = (event, newPage) => {
    setInvoiceSetting((prev) => ({
      ...prev,
      currentPage: newPage + 1,
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setInvoiceSetting((prev) => ({
      ...prev,
      currentPage: 1,
      pageSize: parseInt(event.target.value, 10),
    }));
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, paymentDue]);

  const dueOptions = [
    {
      field: "over-due",
      fieldName: "Over Due",
      active: true,
    },
    {
      field: "three-days",
      fieldName: "Due within three days",
      active: true,
    },
    {
      field: "one-week",
      fieldName: "Due within one week",
      active: true,
    },
    {
      field: "one-month",
      fieldName: "Due within one month",
      active: true,
    },
  ];

  return (
    <CommonAccordion className="PaymentDueAccordion" title="Payment Due">
      <Grid container justifyContent="flex-end">
        <Grid
          item
          xs={8}
          sm={6}
          md={4}
          lg={3}
          style={{ padding: "0 28px 20px 0" }}
        >
          <CommonSelect
            options={dueOptions}
            valueKey="field"
            labelKey="fieldName"
            fullWidth
            value={paymentDue}
            onChange={(e) => setPaymentDue(e.target.value)}
          />
        </Grid>
      </Grid>
      <CustomTablePagination
        loading={isLoading}
        totalElements={pageData?.totalElements}
        size={invoiceSetting.pageSize}
        page={invoiceSetting.currentPage}
        handleChangePage={handleChangePage}
        handleChangeSize={handleChangeRowsPerPage}
      />
      <InvoiceContent
        invoiceData={pageData && pageData.content ? pageData.content : []}
        isLoading={isLoading}
        setRefetch={setRefetch}
        hidePaymentDueCol={false}
      />
      <InvoiceSummary data={pageData?.content} isLoading={isLoading} />
    </CommonAccordion>
  );
};
