import 'dotenv/config';
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Axios from "axios";
import CONSTANTS from "./utils/constants";

Axios.defaults.baseURL = CONSTANTS.API_BASE_URL;
Axios.defaults.headers["Authorization"] = `Bearer ${localStorage.getItem(
  CONSTANTS.ACCESS_TOKEN
)}`;

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
